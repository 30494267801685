
          @import "@/assets/css/vars.scss";
        


















.single-sect-wrap{
    .single-sect-content{
        max-width: 1920px;
        margin: 0 auto;
        .desktop{
            max-width: 100%;
            display: block;
        }
        .mobile{
            display: none;
            width: 100%;
        }
    }
}
@media only screen and (max-width: 700px) {
    .single-sect-wrap{
        .single-sect-content{
            .desktop{
                display: none;
            }
            .mobile{
                display: block;
            }
        }
    }
}

