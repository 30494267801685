
          @import "@/assets/css/vars.scss";
        




























































.input-wrap-component {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 17px;
  .abs-img {
    position: absolute;
    left: calc(100% - 20px);
    top: 50%;
    transform: translateY(20%);
    cursor: pointer;
  }

  .smallModal {
    position: absolute;
    height: 70px;
    width: 150px;
    left: calc(92% - 150px);
    top: -23%;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    background: #fff;
    font-size: 11px;
    padding: 8px;
    text-align: center;
    color: #757983;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #757983;
    display: inline-block;
    margin-bottom: 7px;
  }
  input {
    width: 366px;
    height: 45px;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 16px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #5f646f;
    &:focus {
      outline: none;
    }
  }
}

@media only screen and (max-width: 420px) {
  .input-wrap-component {
    input {
      width: 100%;
    }
  }
}
