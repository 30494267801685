
          @import "@/assets/css/vars.scss";
        
























.home-modal-toast {
  min-height: 220px;
  padding: 40px 0;
  width: 100%;
  margin: 30px auto;
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  span {
    color: rgb(214, 59, 59);
    text-align: center;
  }
}
